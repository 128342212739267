import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faEnvelope, faPhone, faCheck, faFile,
    faUserFriends as faUserFriendsSolid,
    faSlash as faSlashSolid,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
    faChevronLeft as faChevronLeftSolid,
    faChevronRight as faChevronRightSolid,
    faTag as faTagSolid,
    faBriefcase, faCompress, faDesktop,
    faShieldAlt as faShieldAltSolid,
    faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faCloudDownloadAlt, faUnlock,
    faDownload as faDownloadSolid,
    faFileAlt as faFileAltSolid,
    faFileInvoice as faFileInvoiceSolid,
    faFileInvoiceDollar as faFileInvoiceDollarSolid,
    faPaperPlane as faPaperPlaneSolid,
    faTshirt as faTshirtSolid,
    faBox as faBoxSolid,
    faTicketAlt as faTicketAltSolid,
    faInbox as faInboxSolid,
    faEye as faEyeSolid,
    faCog as faCogSolid,
    faGem as faGemSolid,
    faGlobe as faGlobeSolid,
    faConciergeBell as faConciergeBellSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faFolderOpen as faFolderOpenSolid,
    faAddressBook as faAddressBookSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faCircle as faCircleSolid,
    faCheckCircle as faCheckCircleSolid,
    faIdBadge as faIdBadgeSolid,
    faLock as faLockSolid,
    faCalendarAlt as faCalendarAltSolid,
    // faStar as faStarSolid,
    // faStarHalf as faStarHalfSolid,
    faExternalLinkAlt as faExternalLinkAltSolid,
    faCube as faCubeSolid,
    faCubes as faCubesSolid,
    faCut as faCutSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
    faCircle as faCircleRegular,
    faCopy as faCopyRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
    // faCubes as faCubesLight,
    // faStar as faStarLight,
    faCopy as faCopyLight,
} from '@fortawesome/pro-light-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
    faAngleDoubleRight as faAngleDoubleRightRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faQuestionSquare as faQuestionSquareSolid,
    faBoxHeart as faBoxHeartSolid,
    faFileUser as faFileUserSolid,
    // faPersonCarry as faPersonCarrySolid,
} from '@fortawesome/pro-solid-svg-icons';
// import {
//     faCubes as faCubesThin,
//     faPersonCarry as faPersonCarrySolid,
// } from '@fortawesome/pro-thin-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(faUser, faEnvelope, faPhone, faCheck, faFile,
        faUserFriendsSolid,
        faCheckSquare, faSquare,
        faSlashSolid,
        faPlus, faTrash, faSave, faCamera, faBriefcase, faCompress, faDesktop, faShieldAltSolid,
        faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
        faChevronLeftSolid,
        faChevronRightSolid,
        faTagSolid,
        faHeart, faBuilding, faUserSecret, faFingerprint, faCode, faBars, faUserCircle,
        faPencilAlt, faSearch, faEdit, faInfoCircle,
        faCloudDownloadAlt, faUnlock,
        faDownloadSolid,
        faFileAltSolid,
        faFileInvoiceSolid,
        faFileInvoiceDollarSolid,
        faPaperPlaneSolid,
        faTshirtSolid,
        faBoxSolid,
        faTicketAltSolid,
        faInboxSolid,
        faEyeSolid,
        faCogSolid,
        faGemSolid,
        faGlobeSolid,
        faConciergeBellSolid,
        faChartLineSolid, faFolderSolid,
        faFolderOpenSolid,
        faAddressBookSolid,
        faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
        faAngleDoubleRightRegular,
        faMinusSquare, faPaperclip, faArrowLeft,
        faEllipsisVSolid, faCloudUploadAltSolid, faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSquareSolid,
        faQuestionSolid,
        faBoxHeartSolid,
        faFileUserSolid,
        // faPersonCarrySolid,
        faCircleSolid, faCircleRegular,
        faCheckCircleSolid,
        faIdBadgeSolid,
        faLockSolid,
        faCalendarAltSolid,
        faExternalLinkAltSolid,
        faExternalLinkAltRegular,
        faCubeSolid,
        faCubesSolid,
        faCutSolid,
        faSyncAltRegular,
        faCopyRegular,
        faCopyLight,
        // faStarLight, faStarSolid,
        // faStarHalfSolid,
        // faCubesThin,
        // faCubesLight,
        faEllipsisVRegular,
        farCode);
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
