import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/SignupActivity.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/LoginActivity.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/profile/delete',
            name: 'profile-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-delete" */ '../views/profile/Delete.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/service/settings',
            name: 'service-admin-settings',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-settings" */ '../views/service/Settings.vue'),
        },
        // {
        //     path: '/service/c2',
        //     name: 'service-admin-c2',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/C2.vue'),
        // },
        // {
        //     path: '/service/dns',
        //     name: 'service-admin-dns',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/Dns.vue'),
        // },
        // {
        //     path: '/service/dynamic-shared-domain',
        //     name: 'service-admin-dynamic-shared-domain',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-dynamic-shared-domain" */ '../views/service/DynamicSharedDomain.vue'),
        // },
        {
            path: '/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/Setup.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-dashboard" */ '../views/user/Dashboard.vue'),
        },
        {
            path: '/create-organization',
            name: 'user-create-organization',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-organization" */ '../views/user/CreateOrganization.vue'),
        },
        {
            path: '/organization',
            name: 'user-organization-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-organization-list" */ '../views/user/OrganizationList.vue'),
        },
        {
            path: '/organization/:organizationId',
            name: 'organization-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-dashboard" */ '../views/organization/Dashboard.vue'),
        },
        {
            path: '/organization/:organizationId/settings',
            name: 'organization-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-settings" */ '../views/organization/Settings.vue'),
        },
        {
            path: '/organization/:organizationId/edit/storefront/settings',
            name: 'organization-edit-storefront-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-storefront-settings" */ '../views/organization/EditSiteSettings.vue'),
        },
        {
            path: '/organization/:organizationId/setup',
            name: 'organization-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-setup" */ '../views/organization/Setup.vue'),
        },
        {
            path: '/organization/:organizationId/delete',
            name: 'organization-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-delete" */ '../views/organization/Delete.vue'),
        },
        {
            path: '/organization/:organizationId/create/client-token',
            name: 'organization-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-client-token" */ '../views/organization/CreateClientToken.vue'),
        },
        {
            path: '/organization/:organizationId/create/coupon',
            name: 'organization-create-coupon',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-coupon" */ '../views/organization/CreateCoupon.vue'),
        },
        {
            path: '/organization/:organizationId/create/coupon-code',
            name: 'organization-create-coupon-code',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-coupon-code" */ '../views/organization/CreateCouponCode.vue'),
        },
        {
            path: '/organization/:organizationId/create/coupon-email',
            name: 'organization-create-coupon-email',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-coupon-email" */ '../views/organization/CreateCouponEmail.vue'),
        },
        {
            path: '/organization/:organizationId/create/product',
            name: 'organization-create-product',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-product" */ '../views/organization/CreateProduct.vue'),
        },
        {
            path: '/organization/:organizationId/create/product-group',
            name: 'organization-create-product-group',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-product-group" */ '../views/organization/CreateProductGroup.vue'),
        },
        {
            path: '/organization/:organizationId/create/service',
            name: 'organization-create-service',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-service" */ '../views/organization/CreateService.vue'),
        },
        {
            path: '/organization/:organizationId/create/webhook-receiver',
            name: 'organization-create-webhook-receiver',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-webhook-receiver" */ '../views/organization/CreateWebhookReceiver.vue'),
        },
        {
            path: '/organization/:organizationId/create/webhook-key',
            name: 'organization-create-webhook-key',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-create-webhook-key" */ '../views/organization/CreateWebhookKey.vue'),
        },
        {
            path: '/organization/:organizationId/edit/authentication-mode',
            name: 'organization-edit-authentication-mode',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-authentication-mode" */ '../views/organization/EditAuthenticationMode.vue'),
        },
        {
            path: '/organization/:organizationId/edit/brandprofile',
            name: 'organization-edit-brandprofile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-brandprofile" */ '../views/organization/EditBrandProfile.vue'),
        },
        {
            path: '/organization/:organizationId/edit/client',
            name: 'organization-edit-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-client" */ '../views/organization/EditClient.vue'),
        },
        {
            path: '/organization/:organizationId/edit/coupon',
            name: 'organization-edit-coupon',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-coupon" */ '../views/organization/EditCoupon.vue'),
        },
        {
            path: '/organization/:organizationId/edit/coupon-code',
            name: 'organization-edit-coupon-code',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-coupon-code" */ '../views/organization/EditCouponCode.vue'),
        },
        {
            path: '/organization/:organizationId/edit/coupon-email',
            name: 'organization-edit-coupon-email',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-coupon-coemailde" */ '../views/organization/EditCouponEmail.vue'),
        },
        {
            path: '/organization/:organizationId/edit/hostname',
            name: 'organization-edit-hostname',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-hostname" */ '../views/organization/EditHostname.vue'),
        },
        {
            path: '/organization/:organizationId/edit/registration-mode',
            name: 'organization-edit-registration-mode',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-registration-mode" */ '../views/organization/EditRegistrationMode.vue'),
        },
        {
            path: '/organization/:organizationId/edit/stripe',
            name: 'organization-edit-stripe',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-stripe" */ '../views/organization/EditStripe.vue'),
        },
        {
            path: '/organization/:organizationId/edit/subscription',
            name: 'organization-edit-subscription',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-subscription" */ '../views/organization/EditSubscription.vue'),
        },
        {
            path: '/organization/:organizationId/edit/transaction-fee',
            name: 'organization-edit-transaction-fee',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-transaction-fee" */ '../views/organization/EditTransactionFee.vue'),
        },
        {
            path: '/organization/:organizationId/edit/product',
            name: 'organization-edit-product',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-product" */ '../views/organization/EditProduct.vue'),
        },
        {
            path: '/organization/:organizationId/edit/product-group',
            name: 'organization-edit-product-group',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-product-group" */ '../views/organization/EditProductGroup.vue'),
        },
        // {
        //     path: '/organization/:organizationId/edit/service',
        //     name: 'organization-edit-service',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "organization-edit-service" */ '../views/organization/EditService.vue'),
        // },
        {
            path: '/organization/:organizationId/edit/webhook-receiver',
            name: 'organization-edit-webhook-receiver',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-webhook-receiver" */ '../views/organization/EditWebhookReceiver.vue'),
        },
        {
            path: '/organization/:organizationId/edit/webhook-key',
            name: 'organization-edit-webhook-key',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-edit-webhook-key" */ '../views/organization/EditWebhookKey.vue'),
        },
        {
            path: '/organization/:organizationId/search/admin',
            name: 'organization-search-admin',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-admin" */ '../views/organization/SearchAdmin.vue'),
        },
        {
            path: '/organization/:organizationId/search/account',
            name: 'organization-search-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-account" */ '../views/organization/SearchAccount.vue'),
        },
        {
            path: '/organization/:organizationId/search/account-user',
            name: 'organization-search-account-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-account-user" */ '../views/organization/SearchAccountUser.vue'),
        },
        {
            path: '/organization/:organizationId/search/client',
            name: 'organization-search-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-client" */ '../views/organization/SearchClient.vue'),
        },
        {
            path: '/organization/:organizationId/search/client-token',
            name: 'organization-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-client-token" */ '../views/organization/SearchClientToken.vue'),
        },
        {
            path: '/organization/:organizationId/search/coupon',
            name: 'organization-search-coupon',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-coupon" */ '../views/organization/SearchCoupon.vue'),
        },
        {
            path: '/organization/:organizationId/search/invoice',
            name: 'organization-search-invoice',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-invoice" */ '../views/organization/SearchInvoice.vue'),
        },
        {
            path: '/organization/:organizationId/search/order',
            name: 'organization-search-order',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-order" */ '../views/organization/SearchOrder.vue'),
        },
        {
            path: '/organization/:organizationId/search/payment',
            name: 'organization-search-payment',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-payment" */ '../views/organization/SearchPayment.vue'),
        },
        {
            path: '/organization/:organizationId/search/product',
            name: 'organization-search-product',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-product" */ '../views/organization/SearchProduct.vue'),
        },
        {
            path: '/organization/:organizationId/search/product-group',
            name: 'organization-search-product-group',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-product-group" */ '../views/organization/SearchProductGroup.vue'),
        },
        {
            path: '/organization/:organizationId/search/refund',
            name: 'organization-search-refund',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-refund" */ '../views/organization/SearchRefund.vue'),
        },
        {
            path: '/organization/:organizationId/search/service',
            name: 'organization-search-service',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-service" */ '../views/organization/SearchService.vue'),
        },
        {
            path: '/organization/:organizationId/search/user',
            name: 'organization-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-user" */ '../views/organization/SearchUser.vue'),
        },
        {
            path: '/organization/:organizationId/search/webhook-receiver',
            name: 'organization-search-webhook-receiver',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-webhook-receiver" */ '../views/organization/SearchWebhookReceiver.vue'),
        },
        {
            path: '/organization/:organizationId/search/webhook-key',
            name: 'organization-search-webhook-key',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-webhook-key" */ '../views/organization/SearchWebhookKey.vue'),
        },
        {
            path: '/organization/:organizationId/search/webhook-message',
            name: 'organization-search-webhook-message',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-search-webhook-message" */ '../views/organization/SearchWebhookMessage.vue'),
        },
        {
            path: '/organization/:organizationId/invite/user',
            name: 'organization-invite-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-invite-user" */ '../views/organization/InviteUser.vue'),
        },
        // {
        //     path: '/organization/:organizationId/product/:productId',
        //     name: 'organization-view-product',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "organization-view-product" */ '../views/organization/ViewProduct.vue'),
        // },
        {
            path: '/organization/:organizationId/admin/:adminId',
            name: 'organization-view-admin',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-admin" */ '../views/organization/ViewAdmin.vue'),
        },
        {
            path: '/organization/:organizationId/account/:accountId',
            name: 'organization-view-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-account" */ '../views/organization/ViewAccount.vue'),
        },
        {
            path: '/organization/:organizationId/user/:userId',
            name: 'organization-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-user" */ '../views/organization/ViewUser.vue'),
        },
        {
            path: '/organization/:organizationId/invoice/:invoiceId',
            name: 'organization-view-invoice',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-invoice" */ '../views/organization/ViewInvoice.vue'),
        },
        {
            path: '/organization/:organizationId/order/:orderId',
            name: 'organization-view-order',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-order" */ '../views/organization/ViewOrder.vue'),
        },
        {
            path: '/organization/:organizationId/payment/:paymentId',
            name: 'organization-view-payment',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-payment" */ '../views/organization/ViewPayment.vue'),
        },
        {
            path: '/organization/:organizationId/refund/:refundId',
            name: 'organization-view-refund',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-refund" */ '../views/organization/ViewRefund.vue'),
        },
        {
            path: '/organization/:organizationId/service/:serviceId',
            name: 'organization-view-service',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-service" */ '../views/organization/ViewService.vue'),
        },
        {
            path: '/organization/:organizationId/webhook-message/:webhookMessageId',
            name: 'organization-view-webhook-message',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-view-webhook-message" */ '../views/organization/ViewWebhookMessage.vue'),
        },
        // {
        //     path: '/organization/:organizationId/product/:productId/delete',
        //     name: 'organization-delete-product',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "organization-delete-product" */ '../views/organization/DeleteProduct.vue'),
        // },
        {
            path: '/organization/:organizationId/delete/client-token',
            name: 'organization-delete-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "organization-delete-client-token" */ '../views/organization/DeleteClientToken.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
