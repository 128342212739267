import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        organizationId: null, // organization id, set by App.vue for /org routes
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and organization info from server
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // userId, isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        organization: null, // current organization; populated when the path has the `organizationId` parameter; see App.vue
        organizationList: null, // user's linked organizations
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: { init: true },
        clientPermitOriginProtocolChoices: [
            { text: 'HTTP', value: 'http' },
            { text: 'HTTPS', value: 'https' },
        ],
        productTypeChoices: [
            // physical items to ship to a customer, or to be picked up by a customer
            // { text: 'Artwork', value: 'artwork' },
            { text: 'Bundle', value: 'bundle' },
            // { text: 'Unique', value: 'unique' }, // for one-of-a-kind items such as original art, autographed items, etc. so it's flat pricing only (because there is only one) and it's one-time only (not recurring -- if customer needs financing that would be a separate feature); and also we automatically have a stock of 1, so when it's purchased it will not be available to other customers
            { text: 'Clothing', value: 'clothing' },
            { text: 'Donation', value: 'donation' }, // flat price, one-time or recurring
            { text: 'Download', value: 'download' }, // digital download such as e-book, or software
            // { text: 'Electronics', value: 'electronics' },
            { text: 'Membership', value: 'membership' },
            { text: 'Merchandise', value: 'merchandise' },
            // { text: 'Motor Vehicle', value: 'motor-vehicle' }, // regulations may apply
            { text: 'SaaS', value: 'saas' }, // a subscription for use of an online service; typically the service stores customer data, so unlike a magazine subscription where you can cancel and don't need anything else from them, when you cancel a saas you might want to export your data first or migrate to another provider; saas product type allow metered billing and also per-transaction price variations or end-of-billing-period invoice items based on total monthly volume or total monthly usage of one or more resources
            { text: 'SaaS Platform', value: 'saas-platform', isPlatform: true }, // saas-platform allows to charge a per-transaction fee as a percentage of the transaction amount
            { text: 'Subscription', value: 'subscription' }, // subscription to physical item shipment or digital item download or service access; the items to be shipped or accessed are pre-defined; metered billing is not available; for a subscription with a-la-cart item purchases, it can be combined with merchandise/download/ticket purchases that require subscription/membership or apply a special price to subscribers/members.
            { text: 'Ticket', value: 'ticket' },
            // temporary use of physical items
            // { text: 'Rental', value: 'rental' }, // short-term rental of a physical item, vehicle, or space
            // { text: 'Lease', value: 'lease' }, // long-term lease agreement for a physical item, vehicle, or space
        ],
        productPublishedChoices: [
            { text: 'Published', value: true },
            { text: 'Draft', value: false },
        ],
        serviceTypeChoices: [
            { text: 'Membership', value: 'membership' },
            { text: 'SaaS', value: 'saas' }, // a subscription for use of an online service; typically the service stores customer data, so unlike a magazine subscription where you can cancel and don't need anything else from them, when you cancel a saas you might want to export your data first or migrate to another provider; saas product type allow metered billing and also per-transaction price variations or end-of-billing-period invoice items based on total monthly volume or total monthly usage of one or more resources
            { text: 'SaaS Platform', value: 'saas-platform', isPlatform: true }, // saas-platform allows to charge a per-transaction fee as a percentage of the transaction amount
        ],
        priceBillingMethodProperties: {
            free: {
                quantity: false,
                licensed: true,
            },
            flat: {
                quantity: false,
                licensed: true,
            },
            custom: {
                quantity: false,
                licensed: true,
                custom: true,
            },
            unit: {
                quantity: true,
                licensed: true,
                metered: true,
            },
            volume: {
                quantity: true,
                licensed: true,
                metered: true,
            },
            stacked: {
                quantity: true,
                licensed: true,
                metered: true,
            },
            package: {
                quantity: true,
                licensed: true,
                metered: true,
            },
        },
        couponTypeChoices: [
            { text: 'Invoice', value: 'invoice', icon: ['fas', 'paper-plane'], description: 'You can apply this coupon to an invoice before sending it' },
            { text: 'Order', value: 'order', icon: ['fas', 'inbox'], description: 'Coupon applies to the entire order' },
            { text: 'Product', value: 'product', icon: ['fas', 'cube'], description: 'Coupon applies to specific products in an order' },
            { text: 'Fee', value: 'fee', icon: ['fas', 'file-invoice-dollar'], description: 'Coupon applies to order fees such as shipping or taxes' },
        ],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        session(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        organization(state, organization) {
            state.organization = organization;
        },
        organizationList(state, organizationList) {
            state.organizationList = organizationList;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        // async createOrganization({ commit, dispatch, state }, organizationInfo) {
        //     commit('loading', { createOrganization: true });
        //     const response = await client.user.create(organizationInfo);
        //     if (response.isCreated) {
        //         await dispatch('loadSession');
        //         if (state.session.isAuthenticated) {
        //             await dispatch('loadUser');
        //             // await dispatch('loadOrganization');
        //         }
        //     }
        //     commit('loading', { createOrganization: false });
        //     return response;
        // },
        async logout({ commit }) {
            commit('loading', { logout: true });
            await client.main().authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('session', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.main().authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('session', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                console.log('vuex store: loaded service info and session');
                /*
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                */
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                    ]);
                }
                console.log('vuex store: ready');
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        async refresh({ dispatch, state }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                await dispatch('loadSession', { progressIndicator: false });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator: false });
                    await dispatch('loadOrganizationList', { progressIndicator: false });
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [/* versionInfo, */serviceInfo] = await Promise.all([
                    // client.system.getVersion(),
                    client.main().system.getInfo(),
                ]);
                // commit('setServiceVersion', versionInfo);
                commit('setServiceInfo', serviceInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await client.main().authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    user_id: userId,
                    authenticated = false,
                    authenticated_duration: authenticatedDuration = null,
                    unlocked = false,
                    unlocked_duration: unlockedDuration = null,
                    // duration = null,
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                commit('session', {
                    userId,
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                });
            } catch (err) {
                commit('session', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadUser: true });
            }
            try {
                const userInfo = await client.user(state.session.userId).user.get();
                if (userInfo) {
                    commit('setUser', userInfo);
                } else {
                    commit('setUser', {});
                }
            } catch (err) {
                commit('setUser', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadUser: false });
        },
        async loadOrganization({ commit }, { organizationId, progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadOrganization: true });
            }
            try {
                const organizationInfo = await client.organization(organizationId).currentOrganization.get({ include: 'setting' });
                commit('organization', organizationInfo);
            } catch (err) {
                console.log('loadOrganization failed', err);
                commit('organization', null);
            }
            commit('loading', { loadOrganization: false });
        },
        async loadOrganizationList({ commit, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadOrganizationList: true });
                }
                const response = await client.user(state.session.userId).user.getOrganizationList(); // state.session.userId or state.user.id
                if (response?.list) {
                    commit('organizationList', response.list);
                }
            } catch (err) {
                console.error('loadOrganizationList failed', err);
                commit('organizationList', null);
                /* TODO: keep track of the error state too? or provide it in a return value?
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
                */
            } finally {
                commit('loading', { loadOrganizationList: false });
            }
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.main().authn.edit(sessionInfo);
        //         commit('session', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.session.userId).user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('setUser', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.main().interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.main().interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
    },
});
